import { Button as LibButton, IButton } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import classNames from 'classnames/bind';

import buttonStyles from './Button.module.css';
import useTheme from 'services/Theme/useTheme';

const Button: FC<IButton> = ({
    onClick,
    backgroundColor,
    textColor,
    hoverColor,
    size,
    text,
    disabled,
    isLoading,
    withPulsating,
    children,
    rightIcon,
    centerIcon,
    dataLocator,
    borderRadius = 'medium',
    className,
    mb,
}) => {
    const { t } = useTranslation();
    const { name } = useTheme();

    const cx = classNames.bind(buttonStyles, {
        greenTheme: name === 'green',
    });

    return (
        <LibButton
            {...(text && { text: t(text) })}
            onClick={onClick}
            backgroundColor={backgroundColor}
            textColor={textColor}
            hoverColor={hoverColor}
            size={size}
            disabled={disabled}
            isLoading={isLoading}
            withPulsating={withPulsating}
            rightIcon={rightIcon}
            centerIcon={centerIcon}
            dataLocator={dataLocator}
            borderRadius={borderRadius}
            className={cx('button', className)}
            mb={mb}
        >
            {children}
        </LibButton>
    );
};

export { Button };
