import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import i18n from 'i18next';

import { DEFAULT_LANGUAGE } from 'constants/localization';

// import { getLocalizationFromAvailable, getCurrentLocalization } from 'helpers/localization';

// const localeFromStorage = localStorage.getItem('language');
//
// const navigatorLanguage = getLocalizationFromAvailable();
// const languageFromURL = getCurrentLocalization();

i18n.use(backend)
    .use(ICU)
    .use(initReactI18next)
    .init({
        // lng: navigatorLanguage || languageFromURL || localeFromStorage,
        lng: DEFAULT_LANGUAGE,
        fallbackLng: [DEFAULT_LANGUAGE],
        ns: ['translation'],
        preload: [DEFAULT_LANGUAGE],
        debug: false,
        react: {
            useSuspense: false,
        },
        nsSeparator: false,
        keySeparator: false,
    });
i18n.changeLanguage(DEFAULT_LANGUAGE);
